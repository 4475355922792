import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`The Klimarat in Austria`}</h3>
    <p>{`The story begins in 2021, when a grassroots mobilization focused on rectifying Austria’s lack of a climate protection law rapidly generated 380,000 signatures in a petition — almost 4x the threshold for something to be discussed in Parliament. Upon this topic arriving in Parliament, three of the political parties then voted to use €1 million to create nation-wide Citizens Assembly to deal with these questions. This assembly became known as the “Klimarat.” Through stratified lottery selection, 100 Austrians were chosen who, in miniature, represented the range of diversity present in the nation's population. Of these, 84 Austrians committed to serve over 6 weekends to answer the question, “What do we have to do today in order to live in a climate-friendly future tomorrow?” and develop recommendations for Parliament to pass into law. Once the process was underway, the members of the assembly wanted to get feedback from the rest of Austria's citizens on their draft recommendations, and so with support of facilitators Andy Paice and Martin Rausch created a project called "The Climate Council asks Austria" which used Polis to handle the scale and complexity of input from over 5000 Austrians.`}</p>
    <p><strong parentName="p">{`Further information:`}</strong>{` To meet the needs of a European state government, The Computational Democracy Project supported the engagement by responding to GDPR related questions, and did a custom analysis to certify that the conversations were not affected by intentional biasing via bots or coordinated behavior. Zooming out, while the impact of the wave of climate assemblies across Europe and the UK is unimpressive at a national level, within each nation that has held a national climate assembly, smaller governmental units of cities and towns have started responding to citizen recommendations. This is the pattern of climate assemblies observed by Andy Paice.`}</p>
    <h3>{`Links`}</h3>
    <ul>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://klimarat.org/"
        }}>{`https://klimarat.org/`}</a></li>
      <li parentName="ul">{`Documentation: `}<a parentName="li" {...{
          "href": "https://klimarat.org/dokumentation/"
        }}>{`https://klimarat.org/dokumentation/`}</a></li>
      <li parentName="ul">{`Report by facilitators Andy Paice and Martin Rausch:`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.co-intelligence.institute/case-study-polis-climate-council"
        }}>{`https://www.co-intelligence.institute/case-study-polis-climate-council`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://static1.squarespace.com/static/60ac18ed5d96c829aad68946/t/6396585aa815e4335650c5ba/1670797419147/Case+Study+KLIMARAT+%26+POLIS+-+Finalv2-1.pdf"
        }}>{`https://static1.squarespace.com/static/60ac18ed5d96c829aad68946/t/6396585aa815e4335650c5ba/1670797419147/Case+Study+KLIMARAT+%26+POLIS+-+Finalv2-1.pdf`}</a></li>
      <li parentName="ul">{`Der Klimarat YouTube channel "Review: Citizen Assembly for Climate Action 2022 (English subtitles)" `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=3OPk2XUur04"
        }}>{`https://www.youtube.com/watch?v=3OPk2XUur04`}</a></li>
      <li parentName="ul">{`Polis report link: `}<a parentName="li" {...{
          "href": "https://pol.is/report/r8nssrnnnf2bewvtd5f5h"
        }}>{`https://pol.is/report/r8nssrnnnf2bewvtd5f5h`}</a></li>
      <li parentName="ul">{`Open data:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/compdemocracy/openData/tree/master/austria-climate.2vkxcncppn.2022-07-07"
            }}>{`https://github.com/compdemocracy/openData/tree/master/austria-climate.2vkxcncppn.2022-07-07`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/compdemocracy/openData/tree/master/austria-climate.5twd2jsnkf.2022-08-08"
            }}>{`https://github.com/compdemocracy/openData/tree/master/austria-climate.5twd2jsnkf.2022-08-08`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/compdemocracy/openData/tree/master/austria-climate.7z7ejpbmv5.2022-08-08"
            }}>{`https://github.com/compdemocracy/openData/tree/master/austria-climate.7z7ejpbmv5.2022-08-08`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/compdemocracy/openData/tree/master/austria-climate.5tzfrp5eaa.2022-07-07"
            }}>{`https://github.com/compdemocracy/openData/tree/master/austria-climate.5tzfrp5eaa.2022-07-07`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/compdemocracy/openData/tree/master/austria-climate.9xnndurbfm.2022-07-07"
            }}>{`https://github.com/compdemocracy/openData/tree/master/austria-climate.9xnndurbfm.2022-07-07`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      